import { useState } from "react";
import ChartComponent from "../../../../../_components/chart/ChartComponent";
import { Area, Bar } from "@ant-design/plots";
import styles from "../../../styles/_charts.module.scss";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";

const EpgMetric = ({ data }) => {
  const { t } = useTranslation();
  const [isEmpty, setIsEmpty] = useState(false);
  const [dataList, setDataList] = useState({
    data: data,

    xField: "value",
    yField: "type",
    colorField: "type",

    sort: {
      reverse: true,
    },
    label: {
      text: "type",
      position: "middle",
      style: {
        textAlign: (d) => (+d.frequency > 0.008 ? "right" : "start"),
      },
    },
    axis: {
      y: {
        labelFormatter: ".0%",
      },
    },
  });

  const chartHeadName = "Title";
  return (
    <div className={styles["chart-wrapper"]}>
      <ChartComponent
        title={t("epg", { name: chartHeadName })}
        children={isEmpty ? <Empty style={{ height: 400 }} /> : <Bar {...dataList} />}
      />
    </div>
  );
};

export default EpgMetric;
