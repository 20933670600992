import request from "../../../api/request";
import { ap_host } from "../../../helpers/appHost";
import adsInstance from "../api/instace";

// const host = ap_host;
const host = "https://ads.inorain.tv/";

const URL = {
  GET: `${host}admin/clients/getter`,

  CLIENT: `${host}admin/clients`,
};

// const headers = {
//   "x-secret-token": "zGdaIbt5M0eRWd36r1TlWyZMzbwZezox",
//   authorization: "Basic YXJtZW5oYWtvYnlhbjpBa21saTY5NjQ",
// };

const clientApi = {
  GET: (query) => adsInstance.get(URL.GET, { params: query }),
  ADD: (query) => adsInstance.post(URL.CLIENT, query),
  EDIT: (query, id) => adsInstance.put(`${URL.CLIENT}/${id}`, query),
  DELETE: (query) => adsInstance.delete(`${URL.CLIENT}/${query}`, query),
  // GET: (query) => request("GET", URL.CLIENT, query, true, headers, null),
  // ADD: (query) => request("POST", URL.CLIENT, query),
  // EDIT: (query) => request("PUT", URL.CLIENT, query),
  // DELETE: (query) => request("DELETE", URL.CLIENT, query),
};

export default clientApi;
