import { Drawer, Form, Input } from "antd";
import ButtonComponent from "../../../../_components/button/ButtonComponent";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ClientDrawer = ({ open, onClose, onSave, editable }) => {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const onFinish = (values) => {
    const body = {};

    if (editable) {
      body.address = values.address;
      body.balance = values.balance;
      body.email = values.email;
      body.full_name = values.full_name;
      body.notes = values.notes;
    } else {
      body = {
        ...values,
      };
    }

    console.log("body", body, editable);

    onSave(body);
  };

  useEffect(() => {
    if (editable) {
      form.setFieldsValue(editable);
    }
  }, [editable]);

  return (
    <Drawer
      width={500}
      open={open}
      title={editable ? t("edit_client") : t("add_client")}
      destroyOnClose
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            gap: "10",
          }}
        >
          <ButtonComponent
            title={t("cancel")}
            type="default"
            onClick={onClose}
            style={{ marginRight: 8 }}
          />

          <ButtonComponent
            title={t("save")}
            type="primary"
            onClick={() => {
              form.submit();
            }}
          />
        </div>
      }
    >
      <Form
        form={form}
        // name="basic"
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={t("full_name")}
          name="full_name"
          rules={[{ required: true, message: t("full_name_message") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("phone")}
          name="phone"
          rules={[{ required: true, message: t("phone_message") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("email")}
          name="email"
          rules={[
            { required: true, message: t("email_message") },
            { type: "email", message: t("email_validation_message") },
          ]}
        >
          <Input />
        </Form.Item>

        {!editable && (
          <Form.Item
            label={t("password")}
            name="password"
            rules={[{ required: true, message: t("password_message") }]}
          >
            <Input.Password />
          </Form.Item>
        )}

        <Form.Item
          label={t("balance")}
          name="balance"
          rules={[{ required: true, message: t("balance_message") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("address")}
          name="address"
          rules={[{ required: true, message: t("address_message") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("notes")}
          name="notes"
          rules={[{ required: true, message: t("notes_message") }]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ClientDrawer;
