import { useState, useEffect } from "react";
import REQUESTS from "../api/requests";

export function useAddListOPtions() {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.ADS.GET().then((response) => {
      if (!response.error) {
        const data = response.message.rows.map((item) => {
          const label = item.type === "text" ? item.text : item.url;
          return {
            label: label,
            value: item.id,
            ...item,
          };
        });

        setList(data);
      }
    });
  }, []);

  return list;
}

export function useLiveTvOptions() {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.LIVE_TV.CHANNELS.GET()
      .then((response) => {
        if (!response.error) {
          const data = response.message?.rows.map((item) => {
            return {
              label: item.name,
              value: item.id,

              ...item,
            };
          });

          setList(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return list;
}

export function useCollectionsOptions() {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.VOD.COLLECTIONS.GET({ query: JSON.stringify({ limit: 1000000 }) }).then(
      (response) => {
        if (!response.error) {
          const data = response.message?.rows
            .filter((item) => !item?.default)
            .map((item) => {
              return {
                ...item,
                label: item.name,
                value: item.id,
              };
            });

          setList(data);
        }
      }
    );
  }, []);

  return list;
}

export function useLiveTvCategoriesOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.LIVE_TV.CATEGORIES.GET({ query: JSON.stringify({ limit: 1000000 }) }).then(
      (response) => {
        if (!response.error) {
          const data = response.message?.rows.map((item) => {
            return {
              [type]: item.name,
              value: item.id,

              ...item,
            };
          });

          setList(data);
        }
      }
    );
  }, []);

  return list;
}

export function useAgeRestrictionOptions() {
  const list = [];

  for (let i = 0; i <= 21; i++) {
    list.push({
      value: `${i}`,
      label: `${i}`,
    });
  }

  return list;
}

export function useCountriesOptions(type = "label", value = "name") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.COUNTRIES.GET().then((response) => {
      if (!response.error) {
        const data = response.message.map((item) => {
          return {
            [type]: item.name,
            value: item[value],

            ...item,
          };
        });

        setList(data);
      }
    });
  }, []);

  return list;
}

export function useDevicesOptions() {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.DEVICES.GET().then((response) => {
      if (!response.error) {
        const data = response.message.map((item) => {
          return {
            ...item,
            label: item.name,
            value: item.name,
          };
        });

        setList([{ label: "Select all", value: "all" }, ...data]);
      }
    });
  }, []);

  return list;
}

export function useEpgTypesOptions(value = "name") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.EPG.TYPES().then((response) => {
      if (!response.error) {
        const data = response.message.map((item) => {
          return {
            label: item.name,
            value: item[value],
            ...item,
          };
        });

        setList(data);
      }
    });
  }, []);

  return list;
}

export function useArchiveServersOptions() {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.CATCH_UP.SERVERS.GET().then((response) => {
      if (!response.error) {
        const data = response.message.rows.map((item) => {
          return {
            label: item.name,
            value: item.id,
            ...item,
          };
        });

        setList(data);
      }
    });
  }, []);

  return list;
}

export function useUsersOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.USERS.USERS.SEARCH().then((response) => {
      if (!response.error) {
        const data = response.message?.map((item) => {
          return {
            [type]: item.name,
            value: item.id,

            ...item,
          };
        });

        setList(data);
      }
    });
  }, []);

  return list;
}

export function useUsersGroupsOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.USERS.GROUPS.GET({ query: JSON.stringify({ limit: 1000 }) }).then(
      (response) => {
        if (!response.error) {
          const data = response.message?.rows.map((item) => {
            return {
              [type]: item.name,
              value: item.id,

              ...item,
            };
          });

          setList(data);
        }
      }
    );
  }, []);

  return list;
}

export function useCurrencyOptions() {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.CURRENCY().then((response) => {
      if (!response.error) {
        const data = response.message?.rows?.map((item) => {
          return {
            label: item.name,
            value: item.id,

            ...item,
          };
        });

        setList(data);
      }
    });
  }, []);

  return list;
}

export function usePaymentsSubscriptionsOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.PAYMENTS.SUBSCRIPTIONS.GET({ query: JSON.stringify({ limit: 1000 }) }).then(
      (response) => {
        if (!response.error) {
          const data = response.message?.rows?.map((item) => {
            return {
              [type]: item.name,
              value: item.id,

              ...item,
            };
          });

          setList(data);
        }
      }
    );
  }, []);

  return list;
}

export function useTvodPaymentsOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.PAYMENTS.TVOD_PAYMENT.GET({ query: JSON.stringify({ limit: 1000 }) }).then(
      (response) => {
        if (!response.error) {
          const data = response.message?.rows?.map((item) => {
            return {
              [type]: item.name,
              value: item.id,

              ...item,
            };
          });

          setList(data);
        }
      }
    );
  }, []);

  return list;
}

export function useVodGenresOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.VOD.GENRES.GET({ query: JSON.stringify({ limit: 1000 }) }).then(
      (response) => {
        if (!response.error) {
          const data = response.message?.rows?.map((item) => {
            return {
              [type]: item.name,
              value: item.id,
              ...item,
            };
          });

          setList(data);
        }
      }
    );
  }, []);

  return list;
}

export function useYearOptions() {
  const currentDate = new Date().getFullYear();

  const [list, setList] = useState([]);

  useEffect(() => {
    const _list = [];

    for (let i = 1970; i < currentDate; i++) {
      _list.push({
        label: i,
        value: i,
      });
    }

    setList(_list);
  }, []);

  return list;
}

export function useCatchUpServersOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.CATCH_UP.SERVERS.GET().then((response) => {
      if (!response.error) {
        const data = response.message.rows.map((item) => {
          return {
            [type]: item.name,
            value: item.id,
            ...item,
          };
        });
        setList(data);
      }
    });
  }, []);

  return list;
}

export function useStreamerServersOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.STREAMER.SERVERS.GET().then((response) => {
      if (!response.error) {
        const data = response.message.map((item) => {
          return {
            [type]: item.name,
            value: item.id,
            ...item,
          };
        });
        setList(data);
      }
    });
  }, []);

  return list;
}

export function useResolutionOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.TRANSCODERS.RESOLUTIONS.GET({
      query: JSON.stringify({ limit: 100000 }),
    }).then((response) => {
      if (!response.error) {
        let data = response.message.rows.map((item) => {
          return {
            [type]: item.name,
            value: item.id,
            ...item,
          };
        });

        data = data.sort((a, b) => +a.width - +b.width);

        setList(data);
      }
    });
  }, []);

  return list;
}

export function useTranscodersGroupsOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.TRANSCODERS.GROUPS.GET({ query: JSON.stringify({ limit: 100000 }) }).then(
      (response) => {
        if (!response.error) {
          const data = response.message.rows.map((item) => {
            return {
              [type]: item.name,
              value: item.id,
              ...item,
            };
          });
          setList(data);
        }
      }
    );
  }, []);

  return list;
}

export function useTranscodersServersOptions() {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.TRANSCODERS.SERVERS.GET({ query: JSON.stringify({ limit: 100000 }) }).then(
      (response) => {
        if (!response.error) {
          const data = response.message.map((item) => {
            return {
              label: item.name,
              value: item.id,
              ...item,
            };
          });

          setList(data);
        }
      }
    );
  }, []);

  return list;
}

export function useProvidersOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.CONTENT_PROVIDER.GET({ query: JSON.stringify({ limit: 100000 }) }).then(
      (response) => {
        if (!response.error) {
          const data = response.message.map((item) => {
            return {
              [type]: item?.company_name,
              value: item?.id,
              ...item,
            };
          });

          setList(data);
        }
      }
    );
  }, []);

  return list;
}

export function useLanguagesOptions() {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.APPLICATION.LANGUAGES.GET({ query: JSON.stringify({ limit: 100000 }) }).then(
      (response) => {
        if (!response.error) {
          const data = response.message.rows.map((item) => {
            return {
              label: item.name,
              value: item.id,
              ...item,
            };
          });

          setList(data);
        }
      }
    );
  }, []);

  return list;
}

export function usePromoCodeGroupName(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.PROMO_CODES.GET({ query: JSON.stringify({ limit: 100000 }) }).then(
      (response) => {
        if (!response.error) {
          const data = response.message.rows.map((item) => {
            return {
              [type]: item.group_name,
              value: item.group_name,
              ...item,
            };
          });

          const newGroup = data.filter(
            (item, index) => item.value !== data[index + 1]?.value
          );

          setList(newGroup);
        }
      }
    );
  }, []);

  return list;
}
