import { useEffect, useState } from "react";
import { Layout, Menu } from "antd";

import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

import * as actions from "../../redux-store/actions";

import defaultOpenKeys from "./defaultOpenKeys";
import checkPermissions from "./checkPermissions";

import REQUESTS from "../../api/requests";
import requests from "../../server/requests";

import style from "./sidebar.module.scss";
import { APP_VERSION } from "../../config/config";
import { useTranslation } from "react-i18next";

export default function Sidebar({ collapsed }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const { permissions, dashboardSettings } = useSelector((state) => state.globalState);

  const { setPermissions, setPackages } = bindActionCreators(actions, dispatch);

  const [current, setCurrent] = useState(location.pathname);

  useEffect(() => {
    setCurrent(location.pathname);
  }, [location.pathname]);

  const [routes, setRoutes] = useState([]);

  const onClick = (e) => {
    setCurrent(e.key);
  };

  const getPermissions = () => {
    REQUESTS.PROFILE.PERMISSIONS()
      .then((response) => {
        if (response.error) {
          return;
        }

        setPermissions(response.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getServicePackage = () => {
    REQUESTS.USAGE_INFO.CLOUD_SERVICE.GET_SERVICE()
      .then((res) => {
        setPackages(res?.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMovieTranscoderHost = () => {
    requests.vod.movie_transcoder_host((data) => {
      localStorage.removeItem("movie_transcoder_host");
      if (data.movie_transcoder_host) {
        localStorage.setItem(
          "movie_transcoder_host",
          // `${data.movie_transcoder_host}/transcodings`
          `${data.movie_transcoder_host}/`
        );
      }

      if (data.movie_transcoder_key) {
        localStorage.setItem("movie_transcoder_key", `${data.movie_transcoder_key}`);
      }
    });
  };

  useEffect(() => {
    getMovieTranscoderHost();
    getPermissions();
    getServicePackage();
  }, []);

  useEffect(() => {
    navigate(current);
  }, [current]);

  useEffect(() => {
    const list = checkPermissions(permissions, t);

    setRoutes(list);
  }, [permissions, t]);

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className={style["sidebar"]}
      style={{
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <header
        style={{
          height: collapsed ? 120 : 200,
          display: "flex",
          flexDirection: "column",
          // padding: "20px",
        }}
      >
        {dashboardSettings?.logo && (
          <div className={style["header-image-wrapper"]}>
            <img
              alt="Inorain"
              width={collapsed ? 50 : 140}
              src={dashboardSettings?.logo}
            />
          </div>
        )}
        <div className={style["app-version"]}>
          <span>{collapsed ? APP_VERSION : `${t("version")} | ${APP_VERSION}`}</span>
        </div>
      </header>

      <div className={style["sidebar-menu"]}>
        <Menu
          onClick={onClick}
          defaultOpenKeys={[defaultOpenKeys(location.pathname)]}
          onDoubleClick={() => window.location.reload()}
          selectedKeys={[current]}
          mode="inline"
          items={routes}
          className="antd-sidebar-menu"
        />
      </div>
    </Layout.Sider>
  );
}
