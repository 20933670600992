import { Divider, Select } from "antd";
import Container from "../../../../../_components/container/Container";
import { useLanguagesOptions } from "../../../../../hooks/selectOptions";
import { useContext, useEffect, useState } from "react";
import REQUESTS from "../../../../../api/requests";
import selectFromTmdb from "../../logic/selectFromTmdb";

import styles from "./_details.module.scss";
import { getAtLocal } from "../../../../../helpers/storages";
import { TabContext } from "../../MediaContentPage";
import { useTranslation } from "react-i18next";

export default function SearchFromTmdb({ form }) {

  const {t} = useTranslation();

  const languagesOptions = useLanguagesOptions();
  const { setSelectOnTMDB } = useContext(TabContext);

  const [search, setSearch] = useState("");
  const [languageId, setLanguageId] = useState(13);
  const [searchResults, setSearchResults] = useState([]);
  const [selected, setSelected] = useState(null);
  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const [mediaType, setMediaType] = useState("");

  const selectMedia = (item) => {
    REQUESTS.VOD.MEDIA_CONTENT.TMDB.SELECT(item?.media_type, item.id, languageId)
      .then((res) => {
        if (res.error) {
          return;
        }
        const data = res.message;
        selectFromTmdb(data, form);
        setSelectOnTMDB(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSearch = () => {
    if (search?.length >= 3) {
      const searchValue = search.trim();
      setSearchResults([]);
      try {
        REQUESTS.VOD.MEDIA_CONTENT.TMDB.SEARCH(searchValue, languageId)
          .then((res) => {
            if (res.error) {
              return;
            }

            const data = res.message;
            let newList = [];

            for (let i = 0; i < data.length; i++) {
              if (data[i].media_type === "movie" && mediaType === "movie") {
                newList.push(data[i]);
              } else if (data[i].media_type === "tv" && mediaType === "tv_show") {
                newList.push(data[i]);
              }
            }

            const parseSearchData = (data) => {
              let movieName = "";
              if (data.media_type === "movie") {
                movieName = data?.title;
              } else if (data.media_type === "tv") {
                movieName = data?.name;
              }

              if (data.media_type === "movie") {
                return `${movieName} (${data?.release_date?.split("-")[0] || ""})`;
              } else {
                return movieName;
              }
            };

            const _data = newList.map((item) => ({
              value: item.id,
              label: parseSearchData(item),
              ...item,
            }));

            setSearchResults(_data);
            setSelectOnTMDB(false); // Reset the flag on new search
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      onSearch();
    }, 1000);

    return () => clearTimeout(timeout);
  }, [search, languageId]);

  useEffect(() => {
    if (selected !== null) {
      const selectedItem = searchResults.find((item) => item.value === selected);
      if (selectedItem) {
        selectMedia(selectedItem);
      }
    }
  }, [selected, searchResults]);

  useEffect(() => {
    const getLanguage = languagesOptions?.find((item) => item?.iso_code === "en");
    setMediaType(getAtLocal("mediaType") || "movie");
    setDefaultLanguage(getLanguage || "English");
    setSelectOnTMDB(false);
  }, [languagesOptions]);

  return (
    <Container>
      <Divider orientation="left" plain>
        <b>{t("search_from_tmdb")}</b>
      </Divider>

      <div className={styles["search-from-tmdb"]}>
        <Select
          options={languagesOptions}
          onChange={(e) => {
            setLanguageId(e);
            setSearchResults([]);
            setSelectOnTMDB(false);
          }}
          optionLabelProp="label"
          value={languageId}
          showSearch
          filterOption={(input, option) =>
            (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
        />

        <Select
          options={searchResults}
          onChange={(value) => {
            setSelected(value);
            setSelectOnTMDB(false);
          }}
          optionLabelProp="label"
          showSearch
          onSearch={setSearch}
          filterOption={(input, option) =>
            (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
        />
      </div>
    </Container>
  );
}
