import dayjs from "dayjs";

export default function generateMediaFormData({
  form,
  values,
  editableMovieId,
  mediaType,
}) {
  let formData = new FormData();

  if (values.id || editableMovieId) {
    formData.append("id", editableMovieId);
  }

  formData.append("type", mediaType);
  formData.append("name", values.name);
  formData.append("description", values.description || "");

  if (values.note) {
    formData.append("note", values.note || "");
  }
  formData.append("duration", values.duration || 0);

  const ratings = {
    tmdb: values.tmdb || 0,
    metacritic: values.metacritic || 0,
    rotten_tomatos: values.rotten_tomatos || 0,
    imdb: values.imdb || 0,
  };

  if (!editableMovieId) {
    formData.append("rating", JSON.stringify(ratings));
  }

  if (values.original_audio_language) {
    formData.append("original_audio_language", values.original_audio_language || "");
  }

  if (values.badges?.length) {
    formData.append("badges", values.badges);
  } else {
    formData.append("badges", null);
  }

  if (values.license_ending_date) {
    formData.append(
      "license_ending_date",
      dayjs(values?.license_ending_date)?.format("YYYY-MM-DD")
    );
  }

  formData.append("stream_type", values.stream_type || "external ");

  formData.append("trailer_stream_type", values.trailer_stream_type || "");

  if (values.stream_url && values.stream_type === "external") {
    formData.append("stream_url", values.stream_url || "");
  } else {
    formData.append("stream_url", "");
  }

  if (values?.channel_id) {
    formData.append("channel_id", values.channel_id);
  }

  if (form.getFieldValue("transcoding_id") && values.stream_type === "internal") {
    formData.append("transcoding_id", form.getFieldValue("transcoding_id") || "");
  }

  if (form.getFieldValue("trailer_stream_type") === "internal") {
    formData.append(
      "trailer_transcoding_id",
      form.getFieldValue("trailer_transcoding_id" || "")
    );
  } else if (values.trailer_url) {
    formData.append("trailer_url", values.trailer_url);
  } else {
    formData.append("trailer_url", "");
  }

  let newGenres = values.genres?.map((item) => item?.label)?.join(",");

  if (!editableMovieId) {
    formData.append("genres", newGenres || "");
  } else {
    formData.append("genres", values?.genres.join(",") || "");
  }

  const newCountries = values.countries?.map((country) => country?.label)?.join(",");

  if (!editableMovieId) {
    formData.append("countries", newCountries || "");
  } else {
    formData.append("countries", values?.countries.join(",") || "");
  }

  formData.append("credits_time", form.getFieldValue("credits_time"));
  formData.append("intro_start_time", form.getFieldValue("intro_start_time"));
  formData.append("intro_end_time", form.getFieldValue("intro_end_time"));
  formData.append("is_protected", values.is_protected);

  formData.append("is_location_restriction", values.is_location_restriction || false);

  if (values.is_location_restriction) {
    formData.append("available_countries", values.available_countries || "");
  }

  if (values.content_monetization_type) {
    formData.append("content_monetization_type", values.content_monetization_type);

    if (values.content_monetization_type !== "free" && values?.tariffIds) {
      formData.append("tariffIds", values.tariffIds?.join(",") || "");
    }
  }

  if (values.pricing_id) {
    formData.append("pricing_id", values.pricing_id);
  }

  if (!editableMovieId && form.getFieldValue("casts")) {
    formData.append("casts", form.getFieldValue("casts"));
  }

  if (values.pg) formData.append("pg", values.pg);

  if (values?.release_date)
    formData.append(
      "release_date",
      dayjs(values?.release_date).format("YYYY-MM-DD") || 0
    );
  if (values.content_provider_id)
    formData.append("content_provider_id", values.content_provider_id);

  if (typeof values.poster !== "object" && !editableMovieId) {
    formData.append(
      "tmdb_poster",
      values.poster.replace("https://image.tmdb.org/t/p/original/", "")
    );
  } else if (typeof values.poster === "object") {
    formData.append("poster", values.poster || "");
  }
  if (typeof values.backdrop !== "object" && !editableMovieId) {
    formData.append(
      "tmdb_backdrop",
      values.backdrop.replace("https://image.tmdb.org/t/p/original/", "")
    );
  } else if (typeof values.backdrop === "object") {
    formData.append("backdrop", values.backdrop || "");
  }
  if (typeof values.image_title === "object") {
    formData.append("image_title", values.image_title || "");
  } else if (values.image_title == "removed") {
    formData.append("image_title", " ");
  }

  if (typeof values.image_title !== "object" && !editableMovieId) {
    formData.append(
      "tmdb_image_title",
      values.image_title.replace("https://image.tmdb.org/t/p/original/", "")
    );
  }

  if (typeof values.poster_tv === "object") {
    formData.append("poster_tv", values.poster_tv || "");
  } else if (values.poster_tv == "removed") {
    formData.append("poster_tv", " ");
  }

  if (typeof values.highlight_tv === "object") {
    formData.append("highlight_tv", values.highlight_tv || "");
  } else if (values.highlight_tv == "removed") {
    formData.append("highlight_tv", " ");
  }

  if (typeof values.highlight_mobile === "object") {
    formData.append("highlight_mobile", values.highlight_mobile || "");
  } else if (values.highlight_mobile == "removed") {
    formData.append("highlight_mobile", " ");
  }

  const newAdsId = values.ads_id?.map((adId) => adId?.value);

  if (values.ads_id && values.content_monetization_type == "free") {
    if (!newAdsId[0]) {
      formData.append("ads_id", values.ads_id.join(","));
    } else {
      formData.append("ads_id", newAdsId.join(","));
    }
  }

  if (values?.published_at) {
    formData.append(
      "published_date",
      dayjs(values?.published_date).format("YYYY-MM-DD") || 0
    );
  } else {
    formData.append("published_date", "0");
  }

  return formData;
}
