import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TableComponent from "../../../_components/table/TableComponent";
import icons from "../../../config/icons";
import ButtonComponent from "../../../_components/ButtonComponent";
import TableButtons from "../../../_components/table/TableButtons";
import Check from "../../../_components/table/Check";
import { getAtSession, setInLocal, setInSession } from "../../../helpers/storages";
import { getColumnSearchProps } from "../../../config/config";
import { asc_desc } from "../../../helpers/ascDesc";

const ClientTable = ({ getDataSource, loading, handleMenuClick, getAgain }) => {
  const { t } = useTranslation();

  const archiveFilterOPtions = [
    { text: t("archived"), value: true },
    { text: t("not_archived"), value: false },
  ];

  const [dataSource, setDataSource] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState(
    getAtSession("custom_ads_client_search")?.sort || ["id", "DESC"]
  );
  const [search, setSearch] = useState(
    {
      full_name: "",
      address: "",
      phone: "",
      email: "",
      archived: false,
    } || getAtSession("custom_ads_client_search")?.search
  );

  const columns = [
    {
      title: "#",
      key: "id",
      dataIndex: "id",
      align: "center",
      width: 60,
      fixed: "left",
    },
    {
      title: t("name"),
      dataIndex: "full_name",
      key: "full_name",
      align: "center",
      defaultFilteredValue: getAtSession("custom_ads_client_search")?.search?.full_name,
      filteredValue: getAtSession("custom_ads_client_search")?.search?.full_name,
      ...getColumnSearchProps(),
    },
    {
      title: t("address"),
      dataIndex: "address",
      key: "address",
      align: "center",
      defaultFilteredValue: getAtSession("custom_ads_client_search")?.search?.address,
      filteredValue: getAtSession("custom_ads_client_search")?.search?.address,
      ...getColumnSearchProps(),
    },
    {
      title: t("phone"),
      dataIndex: "phone",
      key: "phone",
      align: "center",
      defaultFilteredValue: getAtSession("custom_ads_client_search")?.search?.phone,
      filteredValue: getAtSession("custom_ads_client_search")?.search?.phone,
      ...getColumnSearchProps(),
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      align: "center",
      defaultFilteredValue: getAtSession("custom_ads_client_search")?.search?.email,
      filteredValue: getAtSession("custom_ads_client_search")?.search?.email,
      ...getColumnSearchProps(),
    },
    {
      title: t("notes"),
      dataIndex: "notes",
      key: "notes",
      align: "center",
    },
    {
      title: t("balance"),
      dataIndex: "balance",
      key: "balance",
      align: "center",
      sorter: true,
      defaultSortOrder:
        getAtSession("custom_ads_client_search")?.sort[1] == "id" &&
        asc_desc(getAtSession("custom_ads_client_search")?.sort[1]),
    },
    {
      title: t("archived"),
      dataIndex: "archived",
      key: "archived",
      align: "center",
      defaultFilteredValue: getAtSession("custom_ads_client_search")?.search?.archived,
      filteredValue: getAtSession("custom_ads_client_search")?.search?.archived,
      filters: archiveFilterOPtions,
      filterMultiple: true,
      sorter: true,

      onFilter: (value, record) => true,

      render: (archived) => <Check value={archived} />,
    },
    {
      title: "",
      key: "actions",
      align: "center",
      fixed: "right",
      width: 90,
      render: (text, record) => (
        <TableButtons
          handleMenuClick={(key) => handleMenuClick(key, record)}
          items={[
            {
              key: "edit",
              name: t("edit"),
              icon: icons.EDIT,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
            {
              key: "delete",
              name: t("delete"),
              icon: icons.DELETE,
              question: t("delete_message"),
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    const client = {
      // sort: data.sort,
      page: data.page,
      search: data?.search,
    };

    setCurrentPage(data.page);
    setLimit(data.limit);
    if (data.sort[1]) {
      setSort(data.sort);
      client.sort = data.sort;
    } else {
      setSort(["id", "DESC"]);
      client.sort = ["id", "DESC"];
    }

    setInLocal("pageLimit", client);
    setInSession("custom_ads_client_search", {
      ...getAtSession("custom_ads_client_search"),
      page: data.page,
      limit: data.limit,
      sort: data.sort || ["id", "DESC"],
      search: data?.search,
    });

    const searchData = {
      ...data?.search,
    };

    if (!data?.sort?.archived) {
      searchData.archived = false;
    }
    console.log(searchData);

    setSearch(searchData);
  };

  useEffect(() => {
    const query = {
      page: currentPage,
      limit: limit,
      filter: {
        archived: search.archived,
      },
    };

    if (sort[1]) {
      query.sort = sort;
    }

    if (search.full_name) {
      query.search = {
        ...query.search,
        full_name: search.full_name,
      };
    }

    if (search.address) {
      query.search = {
        ...query.search,
        address: search.address,
      };
    }

    if (search.phone) {
      query.search = {
        ...query.search,
        phone: search.phone,
      };
    }

    if (search.email) {
      query.search = {
        ...query.search,
        email: search.email,
      };
    }

    const success = (data) => {
      setDataSource(data);
      setPageSize(data.total);
    };

    const error = (error) => {
      console.log(error);
    };

    getDataSource(query, success, error);
  }, [currentPage, limit, sort, search, getAgain]);

  return (
    <>
      <TableComponent
        header={
          <div>
            <ButtonComponent
              actionType="add"
              title={t("add_client")}
              style={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => handleMenuClick({ key: "add" }, null)}
            />
          </div>
        }
        columns={columns}
        dataSource={dataSource}
        isLoading={loading}
        onChange={handleTableChange}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: dataSource?.length,
        }}
        scroll={{ x: 1200 }}
      />
    </>
  );
};

export default ClientTable;
